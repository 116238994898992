var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "guarantee-view" }, [
    _vm.ensureGuaranteeList.length > 0
      ? _c(
          "div",
          [
            _vm._m(0),
            _vm._l(_vm.ensureGuaranteeList, function(item, index) {
              return _c(
                "van-cell-group",
                { key: item.id, staticClass: "loan-cell-group" },
                [
                  _c("van-cell", {
                    attrs: { title: "序号", value: index + 1 }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "担保人",
                      value: _vm.utils.isEffectiveCommon(item.operatorName)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "担保额度",
                      value: _vm.utils.moneyFormat(item.quota)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "担保期限",
                      value: _vm.utils.isEffectiveCommon(item.term)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "担保起始日",
                      value: _vm.utils.dateFormat(item.guaranteeDateStart)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "担保到期日",
                      value: _vm.utils.dateFormat(item.guaranteeDateEnd)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "担保合同编号",
                      value: _vm.utils.isEffectiveCommon(item.contractNumber)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "担保合同名称",
                      value: _vm.utils.isEffectiveCommon(item.contractName)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "合同签署日",
                      value: _vm.utils.dateFormat(item.contractSignDate)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "备注",
                      value: _vm.utils.isEffectiveCommon(item.remarks)
                    }
                  }),
                  _c("file-list", {
                    attrs: {
                      "file-list": item.fileDetailInfoVo
                        ? [item.fileDetailInfoVo]
                        : [],
                      title: "合同文件"
                    }
                  })
                ],
                1
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm.pledgeGuaranteeList.length > 0
      ? _c(
          "div",
          [
            _vm._m(1),
            _vm._l(_vm.pledgeGuaranteeList, function(item, index) {
              return _c(
                "van-cell-group",
                { key: item.id, staticClass: "loan-cell-group" },
                [
                  _c("van-cell", {
                    attrs: { title: "序号", value: index + 1 }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "质押人",
                      value: _vm.utils.isEffectiveCommon(item.operatorName)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "质押物",
                      value: _vm.utils.isEffectiveCommon(item.pledge)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "质押额度",
                      value: _vm.utils.moneyFormat(item.quota)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "质押期限",
                      value: _vm.utils.isEffectiveCommon(item.term)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "质押起始日",
                      value: _vm.utils.dateFormat(item.guaranteeDateStart)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "质押到期日",
                      value: _vm.utils.dateFormat(item.guaranteeDateEnd)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "质押合同编号",
                      value: _vm.utils.isEffectiveCommon(item.contractNumber)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "质押合同名称",
                      value: _vm.utils.isEffectiveCommon(item.contractName)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "合同签署日",
                      value: _vm.utils.dateFormat(item.contractSignDate)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "备注",
                      value: _vm.utils.isEffectiveCommon(item.remarks)
                    }
                  }),
                  _c("file-list", {
                    attrs: {
                      "file-list": item.fileDetailInfoVo
                        ? [item.fileDetailInfoVo]
                        : [],
                      title: "合同文件"
                    }
                  })
                ],
                1
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm.mortgageGuaranteeList.length > 0
      ? _c(
          "div",
          [
            _vm._m(2),
            _vm._l(_vm.mortgageGuaranteeList, function(item, index) {
              return _c(
                "van-cell-group",
                { key: item.id, staticClass: "loan-cell-group" },
                [
                  _c("van-cell", {
                    attrs: { title: "序号", value: index + 1 }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "抵押人",
                      value: _vm.utils.isEffectiveCommon(item.operatorName)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "抵押物",
                      value: _vm.utils.isEffectiveCommon(item.pledge)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "抵押额度",
                      value: _vm.utils.moneyFormat(item.quota)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "抵押期限",
                      value: _vm.utils.isEffectiveCommon(item.term)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "抵押起始日",
                      value: _vm.utils.dateFormat(item.guaranteeDateStart)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "抵押到期日",
                      value: _vm.utils.dateFormat(item.guaranteeDateEnd)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "抵押合同编号",
                      value: _vm.utils.isEffectiveCommon(item.contractNumber)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "抵押合同名称",
                      value: _vm.utils.isEffectiveCommon(item.contractName)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "合同签署日",
                      value: _vm.utils.dateFormat(item.contractSignDate)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "备注",
                      value: _vm.utils.isEffectiveCommon(item.remarks)
                    }
                  }),
                  _c("file-list", {
                    attrs: {
                      "file-list": item.fileDetailInfoVo
                        ? [item.fileDetailInfoVo]
                        : [],
                      title: "合同文件"
                    }
                  })
                ],
                1
              )
            })
          ],
          2
        )
      : _vm._e(),
    _vm.otherGuaranteeList.length > 0
      ? _c(
          "div",
          [
            _vm._m(3),
            _vm._l(_vm.otherGuaranteeList, function(item, index) {
              return _c(
                "van-cell-group",
                { key: item.id, staticClass: "loan-cell-group" },
                [
                  _c("van-cell", {
                    attrs: { title: "序号", value: index + 1 }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "其它担保",
                      value: _vm.utils.isEffectiveCommon(item.otherGuarantees)
                    }
                  }),
                  _c("file-list", {
                    attrs: {
                      "file-list": item.fileDetailInfoVo
                        ? [item.fileDetailInfoVo]
                        : [],
                      title: "合同文件"
                    }
                  })
                ],
                1
              )
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 保证担保 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 质押担保 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 抵押担保 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 其它担保 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }