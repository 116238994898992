<template>
  <div class="guarantee-view">
    <div v-if="ensureGuaranteeList.length > 0">
      <div class="area-title">
        <p class="title">
          保证担保
        </p>
      </div>
      <van-cell-group v-for="(item, index) in ensureGuaranteeList" :key="item.id" class="loan-cell-group">
        <van-cell title="序号" :value="index + 1" />
        <van-cell title="担保人" :value="utils.isEffectiveCommon(item.operatorName)" />
        <van-cell title="担保额度" :value="utils.moneyFormat(item.quota)" />
        <van-cell title="担保期限" :value="utils.isEffectiveCommon(item.term)" />
        <van-cell title="担保起始日" :value="utils.dateFormat(item.guaranteeDateStart)" />
        <van-cell title="担保到期日" :value="utils.dateFormat(item.guaranteeDateEnd)" />
        <van-cell title="担保合同编号" :value="utils.isEffectiveCommon(item.contractNumber)" />
        <van-cell title="担保合同名称" :value="utils.isEffectiveCommon(item.contractName)" />
        <van-cell title="合同签署日" :value="utils.dateFormat(item.contractSignDate)" />
        <van-cell title="备注" :value="utils.isEffectiveCommon(item.remarks)" />
        <file-list :file-list="item.fileDetailInfoVo ? [item.fileDetailInfoVo] : []" title="合同文件" />
      </van-cell-group>
    </div>
    <div v-if="pledgeGuaranteeList.length > 0">
      <div class="area-title">
        <p class="title">
          质押担保
        </p>
      </div>
      <van-cell-group v-for="(item, index) in pledgeGuaranteeList" :key="item.id" class="loan-cell-group">
        <van-cell title="序号" :value="index + 1" />
        <van-cell title="质押人" :value="utils.isEffectiveCommon(item.operatorName)" />
        <van-cell title="质押物" :value="utils.isEffectiveCommon(item.pledge)" />
        <van-cell title="质押额度" :value="utils.moneyFormat(item.quota)" />
        <van-cell title="质押期限" :value="utils.isEffectiveCommon(item.term)" />
        <van-cell title="质押起始日" :value="utils.dateFormat(item.guaranteeDateStart)" />
        <van-cell title="质押到期日" :value="utils.dateFormat(item.guaranteeDateEnd)" />
        <van-cell title="质押合同编号" :value="utils.isEffectiveCommon(item.contractNumber)" />
        <van-cell title="质押合同名称" :value="utils.isEffectiveCommon(item.contractName)" />
        <van-cell title="合同签署日" :value="utils.dateFormat(item.contractSignDate)" />
        <van-cell title="备注" :value="utils.isEffectiveCommon(item.remarks)" />
        <file-list :file-list="item.fileDetailInfoVo ? [item.fileDetailInfoVo] : []" title="合同文件" />
      </van-cell-group>
    </div>
    <div v-if="mortgageGuaranteeList.length > 0">
      <div class="area-title">
        <p class="title">
          抵押担保
        </p>
      </div>
      <van-cell-group v-for="(item, index) in mortgageGuaranteeList" :key="item.id" class="loan-cell-group">
        <van-cell title="序号" :value="index + 1" />
        <van-cell title="抵押人" :value="utils.isEffectiveCommon(item.operatorName)" />
        <van-cell title="抵押物" :value="utils.isEffectiveCommon(item.pledge)" />
        <van-cell title="抵押额度" :value="utils.moneyFormat(item.quota)" />
        <van-cell title="抵押期限" :value="utils.isEffectiveCommon(item.term)" />
        <van-cell title="抵押起始日" :value="utils.dateFormat(item.guaranteeDateStart)" />
        <van-cell title="抵押到期日" :value="utils.dateFormat(item.guaranteeDateEnd)" />
        <van-cell title="抵押合同编号" :value="utils.isEffectiveCommon(item.contractNumber)" />
        <van-cell title="抵押合同名称" :value="utils.isEffectiveCommon(item.contractName)" />
        <van-cell title="合同签署日" :value="utils.dateFormat(item.contractSignDate)" />
        <van-cell title="备注" :value="utils.isEffectiveCommon(item.remarks)" />
        <file-list :file-list="item.fileDetailInfoVo ? [item.fileDetailInfoVo] : []" title="合同文件" />
      </van-cell-group>
    </div>
    <div v-if="otherGuaranteeList.length > 0">
      <div class="area-title">
        <p class="title">
          其它担保
        </p>
      </div>
      <van-cell-group v-for="(item, index) in otherGuaranteeList" :key="item.id" class="loan-cell-group">
        <van-cell title="序号" :value="index + 1" />
        <van-cell title="其它担保" :value="utils.isEffectiveCommon(item.otherGuarantees)" />
        <file-list :file-list="item.fileDetailInfoVo ? [item.fileDetailInfoVo] : []" title="合同文件" />
      </van-cell-group>
    </div>
  </div>
</template>
<script>
import { Cell, CellGroup } from 'vant'
import fileList from '@/components/file-list'
export default {
  name: 'GuaranteeList',
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    fileList
  },
  props: {
    businessId: {
      type: String,
      default () {
        return ''
      }
    },
    type: { // 类型 credit 授信  useCredit 用信
      type: String,
      default () {
        return 'credit'
      }
    }
  },
  data () {
    return {
      ensureGuaranteeList: [], // 保证担保
      pledgeGuaranteeList: [], // 质押担保
      mortgageGuaranteeList: [], // 抵押担保
      otherGuaranteeList: [] // 其它担保
    }
  },
  watch: {
    businessId: {
      handler (newVal, oldVal) {
        this.getGuaranteeList()
      },
      immediate: true
    }
  },
  created () {
  },
  methods: {
    getGuaranteeList () {
      let api = this.api.fundManagement.guarantee.list
      if (this.type === 'useCredit') {
        api = this.api.fundManagement.guarantee.selCreditGuaranteeByUse
      }
      api(this.businessId).then(result => {
        const guaranteeList = result.data.value
        this.ensureGuaranteeList = guaranteeList.filter(o => o.guaranteeType === '0')
        this.pledgeGuaranteeList = guaranteeList.filter(o => o.guaranteeType === '1')
        this.mortgageGuaranteeList = guaranteeList.filter(o => o.guaranteeType === '2')
        this.otherGuaranteeList = guaranteeList.filter(o => o.guaranteeType === '3')
      }).catch(e => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
.normal .el-table__header .el-input__suffix {
  height: 27px;
}
.guarantee-view {
  .el-form-item {
    margin-bottom: 0px;
  }
  .loan-cell-group {
    border:1px solid #ccc;
    margin: 10px 15px;
  }
}

</style>
