<template>
  <div class="van-main credit-detail">
    <van-tabs v-model="active">
      <van-tab title="授信信息">
        <div class="van-common-detail">
          <van-cell title="资金来源" :value="utils.statusFormat(creditDetail.sourceFunds, 'FundsSource')" />
          <van-cell title="授信机构" :value="utils.isEffectiveCommon(creditDetail.creditInstitutions)" />
          <van-cell title="分支机构" :value="utils.isEffectiveCommon(creditDetail.branch)" />
          <van-cell title="授信主体" :value="utils.isEffectiveCommon(creditDetail.creditSubject)" />
          <van-cell title="授信合同名称" :value="utils.isEffectiveCommon(creditDetail.creditContractName)" />
          <van-cell title="授信合同编号" :value="utils.isEffectiveCommon(creditDetail.creditContractNumber)" />
          <van-cell title="授信额度(元)" :value="utils.moneyFormat(creditDetail.creditLine)" />
          <van-cell title="授信期限" :value="utils.isEffectiveCommon(creditDetail.creditTerm)" />
          <van-cell title="授信起始日" :value="utils.dateFormat(creditDetail.creditStartDate)" />
          <van-cell title="授信到期日" :value="utils.dateFormat(creditDetail.creditEndDate)" />
          <van-cell title="授信品种" :value="utils.isEffectiveCommon(creditDetail.creditVarieties)" />
          <van-cell title="名义利率（%）" :value="utils.moneyFormat(creditDetail.nominalInterestRate)" />
          <van-cell title="逾期费率（%）" :value="utils.moneyFormat(creditDetail.overdueRate)" />
          <van-cell title="实际利率（%）" :value="utils.moneyFormat(creditDetail.realInsterestRate)" />
          <van-cell title="已使用额度" :value="utils.moneyFormat(creditDetail.amountUsed)" />
          <van-cell title="剩余额度" :value="utils.moneyFormat((creditDetail.creditLine || 0) - (creditDetail.amountUsed || 0))" />
        </div>
      </van-tab>
      <van-tab title="用信情况">
        <div class="area-title">
          <p class="title">
            授信情况
          </p>
        </div>
        <div class="van-common-detail">
          <van-cell-group>
            <van-cell title="资金来源" :value="utils.statusFormat(creditDetail.sourceFunds, 'FundsSource')" />
            <van-cell title="授信机构" :value="utils.isEffectiveCommon(creditDetail.creditInstitutions)" />
            <van-cell title="分支机构" :value="utils.isEffectiveCommon(creditDetail.branch)" />
            <van-cell title="授信主体" :value="utils.isEffectiveCommon(creditDetail.creditSubject)" />
            <van-cell title="授信额度(元)" :value="utils.moneyFormat(creditDetail.creditLine)" />
            <van-cell title="已用额度(元)" :value="utils.moneyFormat(creditDetail.amountUsed)" />
            <van-cell title="剩余授信额度(元)" :value="utils.moneyFormat((creditDetail.creditLine || 0) - (creditDetail.amountUsed || 0))" />
            <van-cell title="授信期限" :value="utils.isEffectiveCommon(creditDetail.creditTerm)" />
            <van-cell title="授信起始日" :value="utils.dateFormat(creditDetail.creditStartDate)" />
            <van-cell title="授信到期日" :value="utils.dateFormat(creditDetail.creditEndDate)" />
          </van-cell-group>
        </div>
        <div class="loan-list">
          <div class="area-title">
            <p class="title">
              用信情况
            </p>
          </div>
          <van-list
            v-model="loanLoading"
            :finished="loanFinished"
            :finished-text="loanFinishText"
            @load="getLoanList"
          >
            <van-cell-group v-for="(item, index) in loanList" :key="item.id" class="loan-cell-group">
              <van-cell title="序号" :value="index + 1" />
              <van-cell title="借款品种" :value="utils.isEffectiveCommon(item.loanVarieties_Name)" />
              <van-cell title="借款合同名称" :value="utils.isEffectiveCommon(item.contractName)" />
              <van-cell title="借款合同编号" :value="utils.isEffectiveCommon(item.contractNo)" />
              <van-cell title="借款本金(元)" :value="utils.moneyFormat(item.amountMoney)" />
              <van-cell title="放款日" :value="utils.dateFormat(item.startDate)" />
              <van-cell title="到期日" :value="utils.dateFormat(item.endDate)" />
              <van-cell title="期限(日)" :value="utils.isEffectiveCommon(item.term)" />
              <van-cell title="借款年利率" :value="utils.moneyFormat(item.annualInterestRate)" />
              <van-cell title="已还款金额（元）" :value="utils.moneyFormat(item.repaymentAmount)" />
              <van-cell title="未还款金额（元）" :value="utils.moneyFormat((item.amountMoney || 0) - (item.repaymentAmount || 0))" />
              <van-cell title="查看放款详情">
                <template #default>
                  <a href="javascript:;" @click="showLoanDetail(item)">放款详情</a>
                </template>
              </van-cell>
              <van-cell title="还款情况">
                <template #default>
                  <a href="javascript:;" @click="showRepayment(item)">还款详情</a>
                </template>
              </van-cell>
              <van-cell title="担保信息">
                <template #default>
                  <a href="javascript:;" @click="showGuarantee(item)">担保信息详情</a>
                </template>
              </van-cell>
            </van-cell-group>
          </van-list>
        </div>
        <van-popup v-model="showLoanDetailPopup" round position="bottom">
          <van-nav-bar
            title="放款详情"
          />
          <van-cell title="借款品种" :value="utils.isEffectiveCommon(loanDetail.loanVarieties_Name)" />
          <van-cell title="借款合同名称" :value="utils.isEffectiveCommon(loanDetail.contractName)" />
          <van-cell title="借款合同编号" :value="utils.isEffectiveCommon(loanDetail.contractNo)" />
          <van-cell title="借款本金(元)" :value="utils.moneyFormat(loanDetail.amountMoney)" />
          <van-cell title="放款日期" :value="utils.dateFormat(loanDetail.startDate)" />
          <van-cell title="到期日期" :value="utils.dateFormat(loanDetail.endDate)" />
          <van-cell title="借款利率(%)" :value="utils.moneyFormat(loanDetail.annualInterestRate)" />
          <van-cell title="备注" :value="utils.isEffectiveCommon(loanDetail.remarks)" />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit" @click="closeLoanDetail">
              关闭
            </van-button>
          </div>
        </van-popup>
        <van-popup v-model="showRepaymentPopup" round position="bottom" class="popup">
          <van-nav-bar
            title="还款详情"
          />
          <div class="list">
            <div v-if="loanDetail">
              <van-cell title="放款日期" :value="utils.dateFormat(loanDetail.startDate)" />
              <van-cell title="到期日期" :value="utils.dateFormat(loanDetail.endDate)" />
              <van-cell v-if="loanDetail.term" title="期限(日)" :value="utils.isEffectiveCommon(loanDetail.term)" />
            </div>
            <van-list
              v-model="repaymentLoading"
              :finished="repaymentFinished"
              :finished-text="repaymentFinishText"
              @load="getRepaymentList"
            >
              <van-cell-group v-for="(item, index) in repaymentList" :key="item.id" class="loan-cell-group">
                <van-cell title="序号" :value="index + 1" />
                <van-cell title="还款本金(元)" :value="utils.moneyFormat(item.repaymentPrincipal)" />
                <van-cell title="还款日期" :value="utils.dateFormat(item.repaymentDate)" />
                <van-cell title="实际使用天数" :value="utils.isEffectiveCommon(item.actualUsageDays)" />
                <van-cell title="资金成本(元)" :value="utils.moneyFormat(item.capitalCost)" />
                <van-cell title="备注" :value="utils.isEffectiveCommon(item.remarks)" />
              </van-cell-group>
            </van-list>
          </div>
          <div class="button-area">
            <van-button round block type="info" native-type="submit" @click="closeRepayment">
              关闭
            </van-button>
          </div>
        </van-popup>
        <van-popup v-model="showGuaranteePopup" round position="bottom" class="popup">
          <van-nav-bar
            title="担保信息"
          />
          <div class="list">
            <guarantee-list :business-id="loanDetail.useCreditId" type="useCredit" />
          </div>
          <div class="button-area">
            <van-button round block type="info" native-type="submit" @click="closeGuarantee">
              关闭
            </van-button>
          </div>
        </van-popup>
      </van-tab>
      <van-tab title="担保信息">
        <guarantee-list :business-id="creditDetail.creditId" type="credit" />
      </van-tab>
    </van-tabs>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, Popup, List, NavBar } from 'vant'
import BackButton from '@/components/back-button'
import GuaranteeList from '@/views/fund/credit/component/guarantee-list'
export default {
  components: {
    BackButton,
    GuaranteeList,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [Popup.name]: Popup,
    [List.name]: List,
    [NavBar.name]: NavBar
  },
  data () {
    return {
      id: this.$route.query.id,
      active: 0,
      creditDetail: {},
      loanList: [],
      loanLoading: false,
      loanFinished: false,
      loanFinishText: '没有更多了',
      showLoanDetailPopup: false,
      loanDetail: {},
      showRepaymentPopup: false,
      repaymentList: [],
      repaymentLoading: false,
      repaymentFinished: false,
      repaymentFinishText: '没有更多了',
      showGuaranteePopup: false
    }
  },
  computed: {
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.api.fundManagement.credit.getDetail(this.$route.query.id).then(result => {
        this.creditDetail = result.data.value
        this.getLoanList()
      }).finally(() => {
      })
    },
    getLoanList () {
      this.api.fundManagement.loan.listPage({ pageSize: 999, creditId: this.creditDetail.creditId }).then(result => {
        this.loanList = result.data.value.list
        this.loanFinished = true
        if (this.loanList.length === 0) {
          this.loanFinishText = '暂无用信信息'
        }
      }).finally(() => {
      })
    },
    showLoanDetail (row) {
      this.showLoanDetailPopup = true
      this.loanDetail = row
    },
    closeLoanDetail () {
      this.showLoanDetailPopup = false
    },
    showRepayment (row) {
      this.showRepaymentPopup = true
      this.loanDetail = row
    },
    getRepaymentList () {
      this.api.fundManagement.repayment.list({ pageSize: 999, useCreditId: this.loanDetail.useCreditId }).then(result => {
        this.repaymentList = result.data.value.list
        this.repaymentFinished = true
        if (this.repaymentList.length === 0) {
          this.repaymentFinishText = '暂无还款信息'
        }
      }).finally(() => {
      })
    },
    closeRepayment () {
      this.showRepaymentPopup = false
    },
    showGuarantee (row) {
      this.loanDetail = row
      this.showGuaranteePopup = true
    },
    closeGuarantee () {
      this.showGuaranteePopup = false
    }
  }
}
</script>

<style lang="less" scoped>
.credit-detail {
  .van-tabs {
      margin-bottom: 70px;
  }
  .loan-cell-group {
    border:1px solid #ccc;
    margin: 10px 15px;
  }
  .van-nav-bar{
    position: static;
  }
  .popup {
    height: 80%;
    .van-nav-bar{
      position: absolute;
      top: 0;
    }
    .list {
      height: 80%;
      margin-top: 40px;
      overflow-y: scroll;
    }
    .button-area {
      position: absolute;
      bottom: 0px;
      height: 55px;
      width: calc(100% - 20px);
      left: 50%;
      margin-left: calc(-50% + 10px);
      background-color: #FFF;
      z-index: 11;
    }
  }
}
</style>
