var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main credit-detail" },
    [
      _c(
        "van-tabs",
        {
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c("van-tab", { attrs: { title: "授信信息" } }, [
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c("van-cell", {
                  attrs: {
                    title: "资金来源",
                    value: _vm.utils.statusFormat(
                      _vm.creditDetail.sourceFunds,
                      "FundsSource"
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "授信机构",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.creditDetail.creditInstitutions
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "分支机构",
                    value: _vm.utils.isEffectiveCommon(_vm.creditDetail.branch)
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "授信主体",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.creditDetail.creditSubject
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "授信合同名称",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.creditDetail.creditContractName
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "授信合同编号",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.creditDetail.creditContractNumber
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "授信额度(元)",
                    value: _vm.utils.moneyFormat(_vm.creditDetail.creditLine)
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "授信期限",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.creditDetail.creditTerm
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "授信起始日",
                    value: _vm.utils.dateFormat(
                      _vm.creditDetail.creditStartDate
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "授信到期日",
                    value: _vm.utils.dateFormat(_vm.creditDetail.creditEndDate)
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "授信品种",
                    value: _vm.utils.isEffectiveCommon(
                      _vm.creditDetail.creditVarieties
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "名义利率（%）",
                    value: _vm.utils.moneyFormat(
                      _vm.creditDetail.nominalInterestRate
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "逾期费率（%）",
                    value: _vm.utils.moneyFormat(_vm.creditDetail.overdueRate)
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "实际利率（%）",
                    value: _vm.utils.moneyFormat(
                      _vm.creditDetail.realInsterestRate
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "已使用额度",
                    value: _vm.utils.moneyFormat(_vm.creditDetail.amountUsed)
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "剩余额度",
                    value: _vm.utils.moneyFormat(
                      (_vm.creditDetail.creditLine || 0) -
                        (_vm.creditDetail.amountUsed || 0)
                    )
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "van-tab",
            { attrs: { title: "用信情况" } },
            [
              _c("div", { staticClass: "area-title" }, [
                _c("p", { staticClass: "title" }, [_vm._v(" 授信情况 ")])
              ]),
              _c(
                "div",
                { staticClass: "van-common-detail" },
                [
                  _c(
                    "van-cell-group",
                    [
                      _c("van-cell", {
                        attrs: {
                          title: "资金来源",
                          value: _vm.utils.statusFormat(
                            _vm.creditDetail.sourceFunds,
                            "FundsSource"
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "授信机构",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.creditDetail.creditInstitutions
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "分支机构",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.creditDetail.branch
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "授信主体",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.creditDetail.creditSubject
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "授信额度(元)",
                          value: _vm.utils.moneyFormat(
                            _vm.creditDetail.creditLine
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "已用额度(元)",
                          value: _vm.utils.moneyFormat(
                            _vm.creditDetail.amountUsed
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "剩余授信额度(元)",
                          value: _vm.utils.moneyFormat(
                            (_vm.creditDetail.creditLine || 0) -
                              (_vm.creditDetail.amountUsed || 0)
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "授信期限",
                          value: _vm.utils.isEffectiveCommon(
                            _vm.creditDetail.creditTerm
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "授信起始日",
                          value: _vm.utils.dateFormat(
                            _vm.creditDetail.creditStartDate
                          )
                        }
                      }),
                      _c("van-cell", {
                        attrs: {
                          title: "授信到期日",
                          value: _vm.utils.dateFormat(
                            _vm.creditDetail.creditEndDate
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "loan-list" },
                [
                  _c("div", { staticClass: "area-title" }, [
                    _c("p", { staticClass: "title" }, [_vm._v(" 用信情况 ")])
                  ]),
                  _c(
                    "van-list",
                    {
                      attrs: {
                        finished: _vm.loanFinished,
                        "finished-text": _vm.loanFinishText
                      },
                      on: { load: _vm.getLoanList },
                      model: {
                        value: _vm.loanLoading,
                        callback: function($$v) {
                          _vm.loanLoading = $$v
                        },
                        expression: "loanLoading"
                      }
                    },
                    _vm._l(_vm.loanList, function(item, index) {
                      return _c(
                        "van-cell-group",
                        { key: item.id, staticClass: "loan-cell-group" },
                        [
                          _c("van-cell", {
                            attrs: { title: "序号", value: index + 1 }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "借款品种",
                              value: _vm.utils.isEffectiveCommon(
                                item.loanVarieties_Name
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "借款合同名称",
                              value: _vm.utils.isEffectiveCommon(
                                item.contractName
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "借款合同编号",
                              value: _vm.utils.isEffectiveCommon(
                                item.contractNo
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "借款本金(元)",
                              value: _vm.utils.moneyFormat(item.amountMoney)
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "放款日",
                              value: _vm.utils.dateFormat(item.startDate)
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "到期日",
                              value: _vm.utils.dateFormat(item.endDate)
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "期限(日)",
                              value: _vm.utils.isEffectiveCommon(item.term)
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "借款年利率",
                              value: _vm.utils.moneyFormat(
                                item.annualInterestRate
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "已还款金额（元）",
                              value: _vm.utils.moneyFormat(item.repaymentAmount)
                            }
                          }),
                          _c("van-cell", {
                            attrs: {
                              title: "未还款金额（元）",
                              value: _vm.utils.moneyFormat(
                                (item.amountMoney || 0) -
                                  (item.repaymentAmount || 0)
                              )
                            }
                          }),
                          _c("van-cell", {
                            attrs: { title: "查看放款详情" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: function($event) {
                                              return _vm.showLoanDetail(item)
                                            }
                                          }
                                        },
                                        [_vm._v("放款详情")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("van-cell", {
                            attrs: { title: "还款情况" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: function($event) {
                                              return _vm.showRepayment(item)
                                            }
                                          }
                                        },
                                        [_vm._v("还款详情")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("van-cell", {
                            attrs: { title: "担保信息" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function() {
                                    return [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: function($event) {
                                              return _vm.showGuarantee(item)
                                            }
                                          }
                                        },
                                        [_vm._v("担保信息详情")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "van-popup",
                {
                  attrs: { round: "", position: "bottom" },
                  model: {
                    value: _vm.showLoanDetailPopup,
                    callback: function($$v) {
                      _vm.showLoanDetailPopup = $$v
                    },
                    expression: "showLoanDetailPopup"
                  }
                },
                [
                  _c("van-nav-bar", { attrs: { title: "放款详情" } }),
                  _c("van-cell", {
                    attrs: {
                      title: "借款品种",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.loanDetail.loanVarieties_Name
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "借款合同名称",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.loanDetail.contractName
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "借款合同编号",
                      value: _vm.utils.isEffectiveCommon(
                        _vm.loanDetail.contractNo
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "借款本金(元)",
                      value: _vm.utils.moneyFormat(_vm.loanDetail.amountMoney)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "放款日期",
                      value: _vm.utils.dateFormat(_vm.loanDetail.startDate)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "到期日期",
                      value: _vm.utils.dateFormat(_vm.loanDetail.endDate)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "借款利率(%)",
                      value: _vm.utils.moneyFormat(
                        _vm.loanDetail.annualInterestRate
                      )
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "备注",
                      value: _vm.utils.isEffectiveCommon(_vm.loanDetail.remarks)
                    }
                  }),
                  _c(
                    "div",
                    { staticStyle: { margin: "16px" } },
                    [
                      _c(
                        "van-button",
                        {
                          attrs: {
                            round: "",
                            block: "",
                            type: "info",
                            "native-type": "submit"
                          },
                          on: { click: _vm.closeLoanDetail }
                        },
                        [_vm._v(" 关闭 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "van-popup",
                {
                  staticClass: "popup",
                  attrs: { round: "", position: "bottom" },
                  model: {
                    value: _vm.showRepaymentPopup,
                    callback: function($$v) {
                      _vm.showRepaymentPopup = $$v
                    },
                    expression: "showRepaymentPopup"
                  }
                },
                [
                  _c("van-nav-bar", { attrs: { title: "还款详情" } }),
                  _c(
                    "div",
                    { staticClass: "list" },
                    [
                      _vm.loanDetail
                        ? _c(
                            "div",
                            [
                              _c("van-cell", {
                                attrs: {
                                  title: "放款日期",
                                  value: _vm.utils.dateFormat(
                                    _vm.loanDetail.startDate
                                  )
                                }
                              }),
                              _c("van-cell", {
                                attrs: {
                                  title: "到期日期",
                                  value: _vm.utils.dateFormat(
                                    _vm.loanDetail.endDate
                                  )
                                }
                              }),
                              _vm.loanDetail.term
                                ? _c("van-cell", {
                                    attrs: {
                                      title: "期限(日)",
                                      value: _vm.utils.isEffectiveCommon(
                                        _vm.loanDetail.term
                                      )
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "van-list",
                        {
                          attrs: {
                            finished: _vm.repaymentFinished,
                            "finished-text": _vm.repaymentFinishText
                          },
                          on: { load: _vm.getRepaymentList },
                          model: {
                            value: _vm.repaymentLoading,
                            callback: function($$v) {
                              _vm.repaymentLoading = $$v
                            },
                            expression: "repaymentLoading"
                          }
                        },
                        _vm._l(_vm.repaymentList, function(item, index) {
                          return _c(
                            "van-cell-group",
                            { key: item.id, staticClass: "loan-cell-group" },
                            [
                              _c("van-cell", {
                                attrs: { title: "序号", value: index + 1 }
                              }),
                              _c("van-cell", {
                                attrs: {
                                  title: "还款本金(元)",
                                  value: _vm.utils.moneyFormat(
                                    item.repaymentPrincipal
                                  )
                                }
                              }),
                              _c("van-cell", {
                                attrs: {
                                  title: "还款日期",
                                  value: _vm.utils.dateFormat(
                                    item.repaymentDate
                                  )
                                }
                              }),
                              _c("van-cell", {
                                attrs: {
                                  title: "实际使用天数",
                                  value: _vm.utils.isEffectiveCommon(
                                    item.actualUsageDays
                                  )
                                }
                              }),
                              _c("van-cell", {
                                attrs: {
                                  title: "资金成本(元)",
                                  value: _vm.utils.moneyFormat(item.capitalCost)
                                }
                              }),
                              _c("van-cell", {
                                attrs: {
                                  title: "备注",
                                  value: _vm.utils.isEffectiveCommon(
                                    item.remarks
                                  )
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "button-area" },
                    [
                      _c(
                        "van-button",
                        {
                          attrs: {
                            round: "",
                            block: "",
                            type: "info",
                            "native-type": "submit"
                          },
                          on: { click: _vm.closeRepayment }
                        },
                        [_vm._v(" 关闭 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "van-popup",
                {
                  staticClass: "popup",
                  attrs: { round: "", position: "bottom" },
                  model: {
                    value: _vm.showGuaranteePopup,
                    callback: function($$v) {
                      _vm.showGuaranteePopup = $$v
                    },
                    expression: "showGuaranteePopup"
                  }
                },
                [
                  _c("van-nav-bar", { attrs: { title: "担保信息" } }),
                  _c(
                    "div",
                    { staticClass: "list" },
                    [
                      _c("guarantee-list", {
                        attrs: {
                          "business-id": _vm.loanDetail.useCreditId,
                          type: "useCredit"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "button-area" },
                    [
                      _c(
                        "van-button",
                        {
                          attrs: {
                            round: "",
                            block: "",
                            type: "info",
                            "native-type": "submit"
                          },
                          on: { click: _vm.closeGuarantee }
                        },
                        [_vm._v(" 关闭 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "担保信息" } },
            [
              _c("guarantee-list", {
                attrs: {
                  "business-id": _vm.creditDetail.creditId,
                  type: "credit"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }